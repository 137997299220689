import * as React from "react";
import { PaletteMode } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Hero from "./components/Hero";
import getLPTheme from "../common/getLPTheme";

export default function PrivacyPage() {
  // const [mode, setMode] = React.useState<PaletteMode>("dark");
  const mode: PaletteMode = "dark";
  const LPtheme = createTheme(getLPTheme(mode));

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <Hero />
    </ThemeProvider>
  );
}
