import React from "react";
import "./App.css";
import LandingPage from "./landing-page/LandingPage";
import { Routes, Route } from "react-router-dom";
import PrivacyPage from "./privacy-page/PrivacyPage";
import DeletePage from "./delete-page/DeletePage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/delete" element={<DeletePage />} />
    </Routes>
  );
}

export default App;
